@import url("https://fonts.googleapis.com/css?family=Montserrat");


$bright: #c7e40a;
$green: #aecc11;
$lime: #a8a80e;
$orange: #ff5100;
$light-orange: #fa6e1d;
$gray: #232020;
$light-gray: #5d5454;
$text: #e9e9e9;
$text-small: #b5b5ae;
$light-blue: #7ba1ac;
$teal: #d59707;
$light-blue_2: #7ba1aca3;
$pink: rgb(255, 54, 111);

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Antology';
  src: url('fonts/Antology.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DroidSansMonoSlashed';
  src: url('fonts/DroidSansMonoSlashed.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Now';
  src: url('fonts/now/Now-Medium.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inconsolata';
  src: url('fonts/Inconsolata-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inconsolata';
  src: url('fonts/Inconsolata-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('fonts/Inter-VariableFont_slnt,wght.ttf');
  font-weight: bold;
  font-style: normal;
}

html {
  overflow: hidden;
}

body {

  box-sizing: border-box;
  font-family: "Inconsolata", monospace;
  background: $lime;
}

.poster {
  position: relative;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 1.0);
  filter: saturate(70%) contrast(85%);

  img {
    width: 100%;
    display: block;
    z-index: 1;
  }

  &:before {
    height: 4%;
    bottom: -4%;
    background-repeat: no-repeat;
    background-image: linear-gradient(177deg, rgba(0, 0, 0, 0.22) 10%, transparent 50%), linear-gradient(-177deg, rgba(0, 0, 0, 0.22) 10%, transparent 50%);
    background-size: 49% 100%;
    background-position: 2% 0, 98% 0;
  }

  &:after {
    height: 100%;
    top: 0;
    z-index: 2;
    background-repeat: no-repeat;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0.1) 0.5%, rgba(0, 0, 0, 0.15) 1.2%, transparent 1.2%), linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0.5%, rgba(0, 0, 0, 0.15) 1.2%, transparent 1.2%), linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0.5%, rgba(0, 0, 0, 0.15) 1.2%, transparent 1.2%), linear-gradient(265deg, rgba(0, 0, 0, 0.2), transparent 10%), linear-gradient(5deg, rgba(0, 0, 0, 0.2), transparent 15%), linear-gradient(-5deg, rgba(0, 0, 0, 0.1), transparent 10%), linear-gradient(5deg, rgba(0, 0, 0, 0.1), transparent 10%), linear-gradient(-265deg, rgba(0, 0, 0, 0.2), transparent 10%), linear-gradient(-5deg, rgba(0, 0, 0, 0.2), transparent 15%), linear-gradient(266deg, rgba(0, 0, 0, 0.2), transparent 10%);
    background-size: 50% 100%, 100% 33.3333%, 100% 33.3333%, 50% 33.3333%, 50% 33.3333%, 50% 33.3333%, 50% 33.3333%, 50% 33.3333%, 50% 33.3333%, 50% 33.3333%;
    background-position: right top, left center, left bottom, left top, left top, right top, left center, right center, right center, left bottom;
  }
}

.poster:before,
.poster:after {
  content: '';
  width: 100%;
  left: 0;
  position: absolute;
}


.banner {
  background: rgb(195, 34, 93);
  background: linear-gradient(147deg, rgb(213, 19, 90) 0%, rgb(230, 137, 30) 100%);
  padding: 10px;
  font-family: 'Montserrat';
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: 200%;
}

.name {
  color: $lime;
  width: 100%;
  font-size: 1.1em;
  padding-top: 0px;
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 7px;
  font-weight: bold;
  overflow-x: visible;
}

@media (max-width: 365px) {
  .name {
    font-size: 1.2em;

  }
}

.canvas-container {
  width: 100%;
  height: 100%;
  position: absolute;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

h1 {
  font-family: "Inconsolata", monospace, normal;


  background: rgb(201, 220, 52);

  outline: thick #000000;
  font-size: 300%;
  text-align: left;
  border-bottom-width: 10px;
  border-color: rgb(255, 255, 255);
  padding: 10px;
  color: #000303;
  border-width: 10px;
}

@media (max-width: 800px) {
  h1 {
    font-size: 200%;
  }
}



.headerContainer {
  display: flex;
  justify-content: center;
}

.post {
  position: absolute;
  scale: .5;
  top: -100%;
  z-index: -2;
}

.make-small {
  @keyframes make-small {
    0% {
      transform: scale(100%)
    }

    100% {
      transform: scale(50%)
    }
  }

  animation: make-small 2s;
  animation-fill-mode: forwards;

}

.main-container {

  width: 100%;
  top: 10%;
  padding: 110px 0 0;
  scroll-margin-bottom: 300px;
  max-height: 60%;
}

@media (max-height: 800px) {
  .main-container {

    padding: 100px 0 0;

    display: block;

    max-height: calc(125vh - 250px) !important;
  }

}

.minimized {
  display: flex;
  padding: 10px;
}

.or {
  color: #383838;
  font-size: 1.5em;
  font-weight: bold;
  margin: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 4%;
  margin-right: 4%;
  justify-content: center;
  text-align: center;
}



.search-container {
  margin: 2%;
  display: flex;
  justify-content: center;
}

.search {
  background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%);

  color: rgb(12, 12, 12);
  font-size: 200%;
  padding: 5px;
  margin: 5px;
  font-weight: 500;
  // border-radius: 100px;
  border: none;
  box-shadow: 10px 10px #404c90;
}

@media (max-width: 800px) {
  .search {
    display: none;
  }
}




.map {
  border-color: rgb(29, 29, 29);

}

.inputs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 70%;
  margin: auto;

}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .inputs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0%;
  }
}




input {
  max-width: 90%;
  display: flex;
  flex-grow: 4;
  font-size: 30px;
  text-align: center;
  background-color: #dee994;
  color: rgb(28, 43, 22);
  font-family: "Now", monospace;

  border: 0
}




@media (max-width: 800px) {
  input {
    font-size: 20px;

    padding: 10px;
  }
}

button {
  font-family: "Inconsolata", monospace;
}

button:active {
  box-shadow: none;

}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}






@media (min-height: 730px) {

  .grow {
    animation-duration: .3s;
    animation-name: grow;
    animation-fill-mode: forwards;
    background-color: #dcf633;
    color: black;
  }
}

@media (max-height: 730px) {
  .inputs {
    input {
      padding: 0px;
    }
  }

  .or {
    margin: 0px;
  }


}

@keyframes grow {
  0% {
    scale: 1;
  }

  50% {
    scale: 1.5;
  }

  75% {
    scale: 1.2;
  }

  100% {
    scale: 1.3;
  }
}

.snail {
  color: rgb(0, 251, 255);
  position: absolute;
  bottom: 30%;
  scale: 80%;
}

.options-container {


  background: rgb(25, 82, 74);
  background: linear-gradient(90deg, rgba(25, 82, 74, 1) 0%, rgba(8, 6, 82, 1) 100%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  margin: auto;
  margin-top: 20px;
  width: 100%;

  div {
    font-size: 300%;
    margin: 10px;
    justify-content: center;
    text-align: center;
    color: rgb(143, 183, 0);

  }
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .options-container {
    height: 120%;
    top: -10%;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 100;

  }
}

@media (max-height: 730px) {
  .options-container {
    font-size: .6em;

    button {
      font-weight: bold;
      margin: 10px;
    }

    font-weight: bold;
    top: -10%;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 100;

  }
}

.address-options {
  background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%);

  color: rgb(12, 12, 12);
  font-size: 150%;
  padding: 2%;
  margin: 30px;
  font-weight: 500;
  // border-radius: 100px;
  border: none;
  box-shadow: 10px 10px #404c90;
}




.fade {
  position: fixed;
  top: 0px;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  background: #282c34c6;
  z-index: 105;
  align-items: center;
}

@keyframes spin3D {
  from {
    transform: rotate3d(.5, .5, .5, 360deg);
  }

  to {
    transform: rotate3d(0deg);
  }
}

.loading {
  position: absolute;
  z-index: 105;
  font-size: calc(10px + 1vmin);
  color: white;
  background: #282c34;
}

.spinner-box {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.leo-border-1 {
  position: absolute;
  width: 200px;
  height: 200px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(63, 249, 220);
  background: linear-gradient(0deg, rgba(63, 249, 220, 0.5) 33%, rgb(193, 63, 249) 100%);
  animation: spin3D 1.8s linear 0s infinite;
}

.leo-core-1 {
  width: 100%;
  height: 100%;
  background-color: #37474f00;
  border-radius: 50%;
}

.leo-border-2 {
  position: absolute;
  width: 200px;
  height: 200px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(111, 251, 83);
  background: linear-gradient(0deg, rgba(181, 251, 83, 0.5) 33%, rgb(173, 251, 83) 100%);
  animation: spin3D 2.2s linear 0s infinite;
}

.leo-core-2 {
  width: 100%;
  height: 100%;
  background-color: #1d263000;
  border-radius: 50%;
}


.close-btn {
  color: #444;
  cursor: pointer;

  transition: 250ms ease;

  &:before,
  &:after {
    content: "";
    height: 2px;
    padding: 5px;
    transition: 250ms ease;
  }

  &:before {
    transform: rotate(-45deg);
  }

  &:after {

    transform: rotate(45deg);
  }

  &:hover,
  &:focus {

    border: 2px solid;
    border-radius: 100%;

    &:before,
    &:after {

      width: 50%;
      left: 25%;
    }
  }
}

.result {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 50;
  align-items: center;
  background: #c8c815;
  overflow-x: hidden;
  overflow-y: hidden;
  padding: 60px 0 0;

}

@media (max-width: 800px) {
  .result {
    scroll-margin-top: 5rem;

  }

  .extra-padding {
    padding: 130px 0 0;
  }

}

.result-container {
  top: 0%;
  width: 100%;
  height: 100%;
  position: relative;
  background: #c8c815;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: -10;

}


.data-container {
  // float:right; width: 50%;
  // display: flex;
  // flex-direction: column;
  padding: 10px;
  overflow: hidden;
  align-items: center;
}

.address-title-container {
  width: fit-content;
  margin: auto;
  margin-top: 20px;
  max-width: 80%;
}

.address-title {
  color: rgb(228, 228, 228);
  font-size: 1.2em;
  text-align: center;
  width: fit-content;
  margin: auto;
  font-weight: normal;
  position: relative;

}

.address-description {
  margin: auto;
  text-align: center;
}

.owner-container {
  width: fit-content;
  display: grid;
  margin: auto;
}

.rate-button-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.rate-button {
  position: absolute;
  top: 45%;
  left: 105%;
  font-size: 1.5em;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  background: #d21010;
  height: 50px;
  border-style: none;
}

.owner-name {
  text-align: center;
  margin: auto;
  margin-bottom: 0px;
  font-size: 2em;
  font-weight: bold;
  background-color: #f2ff00;
  padding: 2px 15px;
  border-radius: 5px;
}



.owned_by {
  color: #b5b5ae;
  position: relative;
  padding: 10px;
  font-weight: bold;
  font-size: 1.1em;
}

.also-owns {
  margin: auto;
  margin-left: 0;
  margin-right: 0;
  color: $lime;
  left: 60%;
  align-content: end;
  font-size: 1.1em;
}

.ampersand {
  font-size: 1.6em;
  color: #b5b5ae;
  width: 100%;
  display: grid;
  text-align: center;
  font-weight: bold;
  padding: 4px;
}


label {
  color: rgb(0, 0, 0);
}

.map-container {
  height: 100%;
  width: 100%;
  z-index: 100;

}

.mapboxgl-canvas {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.small-header {
  position: absolute;
  left: 0%;
  top: -10px;
  font-size: .3em;
}

.data-rows {
  overflow-y: scroll;
  max-height: 250px;
  width: fit-content;
}

.related-businesses-title {
  color: white;
}

.related-businesses-list {
  width: fit-content;
}

.important {
  background-color: rgba(255, 255, 255, 0.391);
  font-size: 25px;
}

.view-business-data-button {
  width: fit-content;
  margin: 5px;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  color: black;
  background-color: $lime;
  font-size: 1.3em;

  font-family: inherit;
  outline: inherit;
  border-style: none;
  border-radius: 10px;

  text-align: left;

  :hover {
    background-color: $teal;
  }
}

@media (max-width: 800px) {
  // .small-header {
  //   position: relative;
  //   left: 0%;
  //   top: -2%;
  //   font-size: .3em;
  // }

  // .headerContainer {
  //   justify-content: left;
  // }
}




h2 {
  align-items: center;
  font-size: 300%;
}

h3 {
  font-size: 25px;
  margin: 10px;
}

h4 {
  font-size: 15px;
  margin-left: 20px;
  margin-top: 0px;
}

.owner-label {
  background-color: rgba(255, 255, 255, 0.45);
  font-size: 1.5em;
  padding: 5px;
  margin-bottom: 10px;
  display: inline-block;
}

table {
  width: 80%;
  table-layout: auto;
  background-color: rgba(205, 205, 205, 0.903);
  border-color: #000000;
  border-collapse: collapse;
  overflow: scroll;
  display: block;

}




th {
  text-align: center;
}

td {
  padding: 3px;
  border: 2px solid rgba(83, 83, 83, 0.647);
  width: 1px;
  white-space: nowrap;
}

table tr:first-child td {
  border-top: 0;
}

table tr td:first-child {
  border-left: 0;
}

table tr:last-child td {
  border-bottom: 0;
}

table tr td:last-child {
  border-right: 0;
}

.bold {
  font-weight: bold;
}



.left {
  top: 2.5%;
  height: 90%;
  left: 2.5%;
  width: 35%;
  position: absolute;
}

.right {
  top: 5%;
  height: 100%;
  right: 0%;
  width: 60%;
  position: fixed;
  overflow-y: scroll;
  background-color: $gray;
  border-width: 10px;
  margin-top: 20px;
  scroll-margin: 130px;
}

.locations-container {
  margin: auto;
  width: 100%;
  max-width: 600px;

}

.counts-container {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
}


.counts-element {

  text-align: center;
  display: inline-block;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  margin: 1%;
  min-width: fit-content;
  padding-bottom: 5px;
  color: $lime
}

.counts-element:before {
  content: '';
  float: left;
  width: auto;
}

.count-number {
  font-size: 2.5em;
}

@keyframes right-up-animation {
  from {
    top: 32%;
    height: 66.66%;
  }

  to {
    top: 0%;
    height: 100%;
  }
}

@keyframes right-down-animation {
  from {
    top: 32%;
    height: 66.66%;
  }

  to {
    top: 0%;
    height: 100%;
  }
}



@media (max-width: 800px) {
  .left {
    top: 0%;
    height: 33.33%;
    width: 100%;
    left: 0%;
    position: absolute;
  }

  .right {
    top: 33.33%;
    height: 66.66%;
    width: 100%;
    right: 0%;
    position: absolute;
    z-index: 100;


  }

  .right-up {
    animation: right-up-animation .4s ease-in;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }

  .right-down {
    animation: right-down-animation .4s ease-in;
    animation-direction: reverse;
    animation-fill-mode: forwards;
  }
}

.main-marker {
  background-image: url('marker.png');
  background-size: cover;
  width: 35px;
  height: 80px;
  cursor: pointer;
  padding: 0;
  z-index: 2;

}


.marker {
  background-image: url('main_marker.png');
  background-size: cover;
  width: 30px;
  height: 60px;
  cursor: pointer;
  padding-bottom: 10px;
}

.question-marker {
  background-image: url('quest_marker.png');
  background-size: cover;
  width: 23px;
  height: 30px;
  cursor: pointer;
  padding-left: 5px;
  padding-bottom: 10px;
}

.owner-home-marker {
  background-image: url('home_marker.png');
  background-size: cover;
  width: 23px;
  height: 30px;
  cursor: pointer;
  padding-left: 5px;
  padding-bottom: 10px;
}

.circle-marker {
  background-image: url('CIRCLE.png');
  background-size: cover;
  width: 10px;
  height: 10px;
  cursor: pointer;
  padding: 0;

}

.mapboxgl-popup-content {
  background-color: rgba(255, 255, 255);

  border-width: 1px !important;
  border-color: white !important;
  padding: 2px 4px 4px !important;

  div {
    text-decoration: none;
    letter-spacing: .05em;
    background-color: transparent;
    font-size: 1em !important;
    font-weight: bold;
    color: black;
  }

  font-family: "Inconsolata",
  monospace,
  normal;
  opacity: 80%;

}


.mapboxgl-popup-tip {
  border-top-color: rgb(255, 255, 255) !important;
  opacity: 80%;
  z-index: -1;
}

.mapboxgl-popup-close-button {
  display: none;
}

.comment-marker {
  font-size: 1.0em;

  &:hover {
    cursor: pointer;
  }
}

.emoji-marker {
  font-size: 1.0em;
  background-color: transparent;

  .mapboxgl-popup-content {
    background-color: transparent;
  }

  transform: scale(3);
}

.emoji-text {
  z-index: 30;
  transform: scale(3);

  &:hover {
    cursor: pointer;
  }
}

.show-others {
  background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%);

  color: rgb(12, 12, 12);
  font-size: 100%;
  padding: 2%;
  margin: 30px;
  font-weight: 500;
  // border-radius: 100px;
  border: none;
  box-shadow: 10px 10px #404c90;

  :hover {
    color: #ff0000
  }
}


.dot {
  height: 20px;
  width: 20px;
  background-color: #000000;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  left: 20px;
  position: absolute;
  top: -5px;
  color: white;
}

.screen {
  position: absolute;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  z-index: 101;
  background-color: #282c346c;
}

.menu-container {
  position: absolute;
  text-align: left;
  width: 200px;
  position: absolute;
  z-index: 102;
  bottom: 0%;
}

.menu-item {
  text-align: right;
  /* height: 10px; */
  /* padding: 10px; */
  padding: 4px;
  background: rgba(142, 145, 62, 0.663);
  color: white;
  display: table;
  margin: 10px;
  border-radius: 10px;
  z-index: 10;
}

.show {
  display: block;
}

.address {
  display: inline;
  white-space: nowrap;
}

.menu-link {
  display: inline;
  // text-align: right;
  background: rgb(179 199 23);
  /* padding-left: 6px 
px
; */
  margin-left: 10px;
  padding-left: 5px;
  // padding-bottom: 3px;
  text-decoration: none;
  font-weight: bolder;
  border-radius: 10px;
  width: 20px;
  font-size: 1.1em;
  /* left: 60%; */
  color: black;
}

.data-row {
  padding-left: 20px;
  padding-right: 20px;
  background-color: $lime;
  margin-bottom: 2px;
  margin-top: 7px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-style: none;
  display: block;
  font-size: 1.3em;
}



.address-group {
  display: block;
  margin: 10px;
  background-color: #afd60097;
  width: fit-content;
  block-size: fit-content;
  padding: 5px;
  padding-left: 10px;
  border-radius: 10px;


}

.address-groups {
  width: 80%;
  max-height: 200px;
  block-size: fit-content;
  overflow-y: scroll;
  background-color: #00000095;
  top: 10%;
  align-items: right;
}

.address-link {
  color: black;
  padding: -2px;
  margin: -2px;
  display: block;
  padding-bottom: 0px;
  margin-bottom: -10px;
  margin-top: 10px;
  padding: 2px;
  font-weight: bold;
  border-radius: 10px;


}

/* Works on Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: rgb(0, 0, 0) rgba(179, 255, 0, 0.397);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: rgba(179, 255, 0, 0.397);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(0, 0, 0);
  border-radius: 20px;
  border: 3px solid rgba(179, 255, 0, 0.397);
}

.address-menu-container {

  width: 50%;
  height: 100%;
  position: absolute;
}

.survey-container {
  margin-left: auto;
  margin-right: auto;
  color: $text-small;
  margin-top: 20px;
  background-color: #000000;
  padding: 10px;
  box-shadow: 10px 10px rgb(0 0 0 / 42%);
  max-width: 500px;
  border-style: solid;
  border-radius: 25px;
  border-width: 2px;
  border-color: $pink;
  scroll-margin: 150px;
}

.survey-title {
  width: fit-content;
  margin: auto;
  margin-bottom: 10px;
  padding: 10px;
  letter-spacing: .2em;
  color: $bright;
  color: $pink;
  text-align: center;
  font-size: 2.0em;
  font-weight: bold;
  padding: 2px 15px;
  border-radius: 5px;
}

.question {
  color: rgb(255, 218, 218);
  box-shadow: 2px 2px rgb(0 0 0 / 42%);
  margin-bottom: 20px;
  font-size: 1.2em;
  padding: 2px;

  p {
    padding-left: 10px;
    margin-bottom: 0px;

  }

  textarea {
    margin-top: 10px;
    padding: 10px;
    font-size: 1.1em;
  }
}



.answer {
  color: $gray;
  background-color: #b6b6b6;
  box-shadow: 5px 5px rgb(0 0 0 / 42%);
  margin: 5px;
  border-style: none;
  font-size: 1.1em;
  padding: 5px;
  border-radius: 5px;
  flex-grow: 4;
  font-weight: bold;
}

.answer.active {
  background-color: #ff5ca2;
  font-weight: bold;
}

.answers {
  display: flex;
}

.business-data {
  display: inline-flex;
  justify-content: space-evenly;

  background: repeating-linear-gradient(45deg,
      #303030,
      #303030 10px,
      #000000 10px,
      #000000 20px);
  border: solid;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.436);
  padding: 5px;
  width: 90%
}




svg {
  padding: 5px;
  background-color: #000000
}

.top-business-node {
  font-family: "Inconsolata", monospace, normal;
  font-size: 3em;
}

.business-node {
  font-family: "Inconsolata", monospace, normal;
  font-size: 1.5em;
}

.via-wrapper {
  padding: 10px;
  display: flex;
}

.via {
  padding: 2px;
  color: #c9c9c9;
  // margin: auto;
  margin-left: 20px;
  font-size: .9em;
}

.via-text {
  color: white;
}

.evictions-wrapper {
  padding: 5px;
  align-items: center;
  display: grid;
  max-width: 100%;
  padding-bottom: 15px;
  padding-top: 15px;
}

.evictions {
  padding: 10px;
  border-style: none;
  font-size: 1.6em;
  font-style: italic;
  font-weight: bold;
  // box-shadow: 6px 6px 0px rgb(255, 145, 0);
  // background-color: $orange;
  // box-shadow: 6px 6px 0px $lime;
  color: $pink;
  width: fit-content;
  margin: auto;
}

.table-wrapper {
  position: absolute;
  left: 1%;
  max-width: 123%;

}



.properties {
  h3 {
    margin: auto;
    width: fit-content;
    color: orange;
  }
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 15px;
  width: fit-content;
  border-style: dashed;
  border-width: 3px;
  border-color: $lime;
  background-color: #000000;
}

.market-value-str {
  margin: auto;
  width: fit-content;
  color: rgb(197, 197, 197);
  font-size: 1.2em;
  text-align: center;
  padding: 10px;
  background-color: black;
}

.market-value {
  margin: auto;
  width: fit-content;
  font-size: 1.8em;
  color: #ffa34cbc;
  font-weight: bold;
  display: flex;
  padding: 10px;

}

@media (max-width: 800px) {
  .full-screen {
    pointer-events: none;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    position: fixed;
    overflow: hidden;
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 1) 100%);
  }

  // .full-screen::before {
  //   animation: bounce 1.5s ease infinite;
  //   bottom: 2rem;
  //   color: #ffffffbd;
  //   content: '⭣';
  //   font-size: 2rem;
  //   height: 4rem;
  //   left: 50%;
  //   letter-spacing: -1px;
  //   line-height: 4rem;
  //   margin-left: -3rem;
  //   opacity: 0.8;
  //   position: absolute;
  //   text-align: center;
  //   width: 6rem;
  // }

  // @keyframes bounce {
  //   50% {
  //     transform: translateY(-50%);
  //   }
  // }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 12px;
}

::-webkit-scrollbar:horizontal {
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

.action-items {
  margin: auto;
  margin-top: 20px;
  padding-bottom: 50px;
  max-width: 500px;
}

.action {
  width: 80%;
  margin: auto;
  margin-top: 20px;
  display: block;
  color: black;
  border-style: none;
  font-size: 1.0em;
  // box-shadow: 10px 10px rgb(121, 121, 121);
  font-weight: bold;
  padding: 15px;
  border-radius: 5px;
}

.orange {
  background-color: #e7881b;
  box-shadow: 3px 3px #ff9d00;

  color: black
}

.green {
  background-color: $lime;
  box-shadow: 3px 3px rgb(204, 255, 0);

  color: black
}

.blue {
  background-color: #339ab6;
  box-shadow: 3px 3px rgb(0, 221, 255);

  color: black
}



.action:after {
  content: '';
  width: 100%;
  left: 0;
  display: block;
  position: absolute;
}

.marker-inline-wrapper {
  width: fit-content;
  position: relative;
}

.marker-inline {
  background-image: url('marker-inline.png');
  background-size: cover;
  width: 50px;
  height: 60px;
  cursor: pointer;
  display: inline-block;
  transform: scale(.5, .5);
  position: absolute;
  left: 50%;
  top: -30%;
}

.main-marker-inline {
  background-image: url('main-marker-inline.png');
  background-size: cover;
  width: 50px;
  height: 60px;
  cursor: pointer;
  display: inline-block;
  transform: scale(.5, .5);
  position: absolute;
  left: 80%;
  top: -30%;
}

textarea {
  width: 100%;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
  background-color: $light-blue;
  border-style: none;
}


.checkbox-wrapper {
  width: 80%;
  margin: auto;
  margin-bottom: 20px;
}


.checkbox-line {
  background-color: #909090;
  display: block;
  position: relative;
  padding: 4px;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: .8em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-line input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.checkbox-line:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-line input:checked~.checkmark {
  background-color: #18819e;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-line:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-line input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-line .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.all-of-them {
  text-align: center;
  background-color: #8989899b;
}

.thanks {
  color: black;
  font-weight: bold;
  text-align: center;
  width: fit-content;
  margin: auto;
}

.business-info {
  width: fit-content;
  margin: auto;
  text-align: center;
}

.hierarchy-icon {
  margin: auto;
  border-style: solid;
  border-color: rgb(101, 149, 30);
}

.hierarchy-line {
  stroke-width: 6
}

.hiearchy-name {
  display: inline-grid;
  color: white;
  margin: 5px;
}

.hierarchy-wrapper {
  position: absolute;
  z-index: 10000;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 85px;
  background-color: #00000093;
}

.hierarchy {
  top: 10%;
  position: relative;
  width: 50%;
  height: 50%;
  margin: auto;
}

@media (max-width: 800px) {
  .hierarchy-wrapper {
    position: fixed;

  }

  .hierarchy {
    top: 20%;
    position: relative;
    width: 100%;
    height: 100%;
    margin: auto;
  }
}

//Settings
$line-height: 8px;
$line-height-hover: 15px;
$line-color: #a1905d;
$line-transition: all .2s cubic-bezier(.25, .46, .45, .94);

.button-close-wrapper {
  width: 100%;
  position: absolute;
  z-index: 1000;
}

.button-close {
  position: relative;
  width: 40px;
  height: 40px;
  background: #b00e0e;
  color: $line-color;
  border: 1px solid #a1905d;
  margin: 100px auto;
  outline: none;
  border-radius: 20px;


  &:hover {
    &:before {
      width: $line-height-hover;
    }

    &:after {
      width: $line-height-hover;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0;
    height: 1px;
    border-left: $line-height solid currentColor;
    border-right: $line-height solid currentColor;
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(-45deg);
    transition: $line-transition;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 0;
    height: 1px;
    border-left: $line-height solid currentColor;
    border-right: $line-height solid currentColor;
    -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
    transition: $line-transition;
  }
}


.submit-button-wrapper {
  text-align: center;
  width: 100%;
}

.submit-button {
  margin: auto;
  width: fit-content;
  padding: 10px;
  font-size: 1.4em;
  border-style: none;
  border-radius: 10px;
  background-color: #ff5ca2;

}

button:disabled,
button[disabled] {
  border: 1px solid #999999;
  background-color: #3f3f3f;
  color: #666666;
}

.button__text {
  transition: all 0.2s;
}

.button--loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.button--loading::after {
  content: "";
  position: absolute;
  left: 49%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}







.report {
  background-image: url('flag.png');
  background-size: cover;
  width: 20px;
  height: 20px;
  cursor: pointer;
  padding: 0px;
  z-index: 2;
  display: inline-block;

}

.disclaimer-screen {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #31391bb8;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.disclaimer {
  font-size: .8em;
  margin: auto;
  margin-top: 10px;
  width: 80%;
  max-width: 500px;
  color: white;
  background-color: #000000;
  padding: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: $orange;

  span {
    display: block;
  }
}

.got-it {
  display: block;
  font-size: 1.2em;
  padding: 10px;
  margin: auto;
  margin-top: 10px;
  border-style: none;
  font-weight: bold;
  max-width: fit-content;
}

.disclaimer-title {
  margin: auto;
  margin-bottom: 10px;
  text-align: center;
  font-size: 1.5em;
}

.ratings-button-wrapper {
  text-align: center;
  margin: auto;
  display: grid;
  margin-top: 10px;

}

.ratings-button {
  display: flex;
  text-align: center;
  align-items: center;
  background-color: #00000000;
  border-style: none;
}

.ratings-circle-wrapper {
  position: relative;
  height: 100%;
  width: 100%;


}

.ratings-circle {
  width: fit-content;
  border-style: solid;
  border-color: $lime;
  background-color: transparent;
  padding: 5px;
  margin: auto;
  margin-top: 20px;

  // border-style: solid;
  // border-color: $orange;

  color: $lime;
  font-size: 20px;
  display: flex;


}

.ratings-circle:hover {
  background-color: $lime;
  color: black;
}


.ratings-warning {
  background-image: url('warning.png');
  background-size: cover;
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: relative;
  padding: 0;
  z-index: 2;

}

.reviews-wrapper {
  display: block;
  text-align: left;
  border-color: $lime;
  border-top-style: none;
  padding: 20px;
  background-color: #a8a80e;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;

  // max-width: 500px;

}

.ratings-wrapper {
  margin: auto;
  width: fit-content;
  margin-top: 50px;
  max-width: 600px;
}

.review-statement {
  font-size: .55em;
  padding: 4px;
}

.reviews-title {
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;

  margin-top: 15px;
  font-size: 1.0em;
  color: $lime;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.reviews-title-singular {
  color: $lime;
  background-color: transparent;
  border-style: solid;
  border-top-left-radius: 50px;
  border-color: $lime;
  padding: 5px;
  flex-grow: 4;
  font-size: 1.1em;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;

}


.review-question {
  font-size: 1.1em;
  font-weight: bold;
  padding: 10px;
  color: $bright;

}

.review-answer {

  font-size: 1.1em;
  padding-left: 10%;

  span.count {
    color: $lime;
  }

  span.statment {
    font-weight: bold;

    color: #e5e5e5
  }
}

.review-section {
  padding-bottom: 20px;
  border-style: solid;
  border-color: $lime;
  border-width: 1px;
  margin-bottom: 10px;
  background-color: #1c1c1c;
  border-radius: 10px;
}

.emoji {
  scale: .5;
}

.review-type-bttn {
  color: $lime;
  background-color: transparent;
  border-style: solid;
  border-top-left-radius: 50px;
  border-color: $lime;
  padding: 5px;
  flex-grow: 4;
  font-size: 1.1em;
  padding-left: 20px;
  padding-right: 20px;
  text-align: right;

  :hover {
    background-color: $lime;
  }
}

.review-type-bttn.selected {
  background-color: $lime;
  color: black;
  font-weight: bold;
  border-style: none;
  font-size: 1.4em;

}

.open-reviews {
  transition: max-height 0.2s ease-out;
}

.comment {
  border-style: none;
  border-width: 1px;
  padding: 10px;
  font-size: 1.1em;
  color: $bright;
  background-color: #1c1c1c;
  border-radius: 10px;
}

.comment-title {
  padding-top: 5px;
  color: black;
  font-weight: bold;
  letter-spacing: .4em;
  font-size: 1.2em;
  text-align: center;
}

.leave-a-review-wrapper {
  width: 100%;
  display: grid;
  padding: 5px;
  font-size: 1.5em;

}

.leave-a-review {
  background-color: $lime;
  border-style: none;
  text-align: center;
  width: fit-content;
  margin: auto;
  font-weight: bold;
  font-size: .8em;
  padding: 10px;
}

.jump-to-reviews-wrapper {
  position: fixed;
  text-align: center;

}

.jump-to-reviews {
  width: 100%;
  position: fixed;
  bottom: 0px;
  color: $gray;
  background-color: $light-orange;
  border-color: $orange;
  border-style: solid;
  font-size: 1.6em;
  padding-top: 5px;
  padding-bottom: 4px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  letter-spacing: .14em;
  font-weight: bold;
  padding-right: 0px;
  padding-bottom: 5px;

}

@keyframes jump-up-animation {
  from {
    bottom: -100px;
  }

  to {
    bottom: 0px;
  }
}

@keyframes jump-down-animation {
  from {
    bottom: 0px;
  }

  to {
    bottom: -100px;
  }
}

.show-jump {
  animation: jump-up-animation .4s ease-in;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

.hide-jump {
  animation: jump-down-animation .4s ease-in;
  animation-direction: normal;
  animation-fill-mode: forwards;
}


.header {
  font-size: 1.6 em;
  background-color: rgb(0, 0, 0);
  font-weight: bold;
  z-index: 100;
  width: 100%;
  display: inline-flex;
  padding-top: 5px;
  position: fixed;
  scroll-margin-top: 5rem;
  border-bottom-style: solid;
  border-color: $lime;
}

@media (max-height: 730px) {
  .header {
    font-size: .8em;
    padding: 2px;
  }
}


.logo {
  background-color: transparent;
  border-style: none;
  margin: auto;

}

.logo img {
  max-width: 100px;

}

.right-buttons {
  text-align: right;
  width: fit-content;
  margin-left: auto;
  margin: auto;
  padding-bottom: 10px;

  button {

    display: inline-block;
    margin-top: 5px;
    margin-right: 10px;
    padding: 5px;
    background-color: transparent;
    border-color: #3c4233;
    border-style: solid;
    color: rgba(219, 219, 219, 0.897);
    font-size: 1.2em;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: bold;
  }

  :hover {
    background-color: #18819e;
  }
}

@media (max-height: 730px) {
  .right-buttons {
    font-size: .9em;
    padding-bottom: 1px;

    button {
      padding: 1px;
    }
  }
}


.donateBtn {
  background-color: #3c4233;
}

.approval-table {
  margin: 10px;
  margin: auto;
  border-style: solid;
  max-width: 600px;
  border-style: none;
  border-radius: 20px;

  td {
    margin: 10px;
    white-space: unset;
    padding: 10px;
    background-color: rgb(255, 235, 196);
    border-radius: 20px;

  }

  button {
    padding: 10px;
    margin: auto;
    align-items: center;
    text-align: center;
    border-style: none;
    border-radius: 50px;
    font-weight: bold;
    letter-spacing: .05em;
  }

  margin-top: 30px;
  display: table;
  padding: 10px;
}

.admin-buttons {
  display: flex;
}

.admin {
  h1 {
    text-align: center;
  }

  .get-content {
    width: fit-content;
    margin: auto;

    button {
      margin: 10px;
      font-size: 1.5em;
      border-radius: 10px;
    }
  }

  overflow: scroll;
  height: 100vh;
}

.approved {
  background-color: $lime;
}

.rejected {
  background-color: rgb(240, 112, 84);
}

.praise {
  background-color: rgb(255, 149, 0);
}

.edit-comment {
  color: white;
  background-color: #007BFF;
}

.save-comment {
  color: white;
  background-color: black;
}

.cancel-comment {
  color: black;
  background-color: rgb(255, 149, 0);
}

@media (max-width: 800px) {
  .right-buttons button {
    font-size: .9em;
    width: fit-content;
    padding-top: 2px;
  }
}



.body {
  // height: 100%;
  // position: relative;
  overflow: scroll;
  max-height: calc(100vh - 48px) !important;

}

.banner {

  width: 100%;
  border-style: none;


  font-family: 'Inter';
  color: #fbe100;
  font-weight: 900;
  letter-spacing: 0.35rem;
  text-transform: uppercase;
  font-size: .8em;

  .lets {
    // color: rgb(0, 0, 0);
    margin-bottom: 5px;
  }

  .vote {
    font-size: 1.2em;
    font-weight: bolder;
    color: #fbe100;

  }

  .vote-date {
    color: white;
    font-size: .7em;
  }

  button {

    font-family: 'Inter';
    font-size: 1.2em;
    padding: 10px;
    background-color: #ffffff90;
    border-style: none;
    margin-top: 5px;
    width: fit-content;
    border-radius: 10px;
    letter-spacing: 0.1rem;

  }

  button:hover {
    background-color: #ff2600;
  }
}



@media (max-height: 730px) {
  .banner {
    font-size: .8em;
    padding: 2px;
  }
}


.banner-wrapper {
  position: relative;

}

.circle-button {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #007BFF;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  font-size: 12px;
  /* Adjust font size as needed */
}

.circle-button::before {
  content: "Stand against Landlord Greed";
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.campaign {
  font-family: 'Inter';
  font-size: 3.2em;

  path {
    fill: transparent;
  }

  text {
    fill: #FF9800;
  }
}

small {
  font-size: .8em;
  font-weight: normal;
  letter-spacing: 0.08rem;
}

.image_logo {
  height: 60px;
  margin: 5px;
}

.organize-wrapper {
  position: absolute;
  width: 100%;
  height: calc(100vh - (140px));
  background-color: rgb(99, 99, 99);
  overflow-y: scroll;
  font-size: 1.0em;
  padding: 130px 0 0;
  scroll-margin-top: 130px;
  scroll-margin-bottom: 500px;
  padding-bottom: 300px;

}

.org-section {
  box-sizing: border-box;
  margin: auto;
  padding: 20px;
  background-color: #323232;
  max-width: 500px;
  letter-spacing: 0.0em;
  scroll-margin-top: 130px;
  width: 90%;
}

.org-title {
  margin: auto;
  font-size: 1.9em;
  color: rgb(187, 255, 0);
  letter-spacing: 0.2em;
  font-weight: bold;
}

.org-question {
  font-size: 1.3em;
  color: white;
  padding: 5px;
}

.org-desc {
  color: rgb(192, 192, 192);

}

.org-add {
  letter-spacing: -0.08rem;

  color: $lime;
  font-size: 1.2em;
}

.org-bullets {
  color: $lime;
  padding-left: 20px;
  padding-right: 20px;
}

.org-bullet {
  display: list-item;
  padding: 10px;
}

.org-answers {
  margin: auto;
  display: flex;

  button {
    background-color: #eaff00;
    border-style: none;
    font-size: 1.5em;
    padding: 10px;
    margin: 5px;
    flex-grow: 4;
    width: fit-content;



    .active {
      background-color: #a2af13;
    }
  }

  .selected {
    font-weight: bold;
    background-color: #7a9c12;
  }

  .disabled {
    background-color: #8c8c8c;
  }
}

.landlord-search-error-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.landlord-search-error {
  position: relative;
  margin: auto;
  width: fit-content;
  background-color: #494949;
  color: whitesmoke;
  font-size: 1.5em;
  padding: 10px;
}

.search-button-nav {
  border-style: none;
  font-size: 1.5em;
  letter-spacing: 0.1rem;

  border-style: none;
  display: inline-block;
  margin-top: 5px;
  margin-right: 10px;
  padding: 5px;
  background-color: #3c4233;
  color: rgb(219, 219, 219);
  font-size: 1.2em;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
}

.dashed {
  margin-top: 20px;
  border-radius: 5px;
}

.mobile-search {}

@media (max-width: 800px) {
  .mobile-search {
    display: block;
    position: relative;

    button {
      position: absolute;
      width: 45px;
      height: 45px;
      border-style: none;
      right: -35px;
      top: -22.5px;
      border-radius: 30px;
      background-color: #18819e;
      font-weight: bold;
      color: white;
      font-size: 1.8em;
    }
  }

  .input-container {
    align-items: center;

  }
}

@media (max-height: 730px) {
  .mobile-search {
    button {
      width: 45px;
      height: 45px;
      right: -5px;
    }
  }
}


.input-container {
}

.downArrow {
  text-align: center;
  width: 100%;
  top: 10%;
  font-weight: bold;
  font-size: 3.0em;
  color: $lime;

}

.org-join {
  border-radius: 40px;
  text-align: center;
  background: radial-gradient(circle, rgba(238, 174, 202, 1) 0%, rgba(148, 187, 233, 1) 100%);
  padding: 10px;

  p {
    background-color: rgba(255, 226, 246, 0.664);
    border-radius: 20px;
    padding: 5px;
    font-weight: normal;
  }

  input {
    padding: 10px;
    margin: auto;
    background-color: rgb(255, 255, 255);
    margin-top: 10px;
  }

  button {
    border-radius: 10px;
    border-style: none;
    margin: 10px;
    font-size: 1.3em;
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    background: radial-gradient(circle, rgb(222, 51, 125) 0%, rgb(159, 37, 135) 100%);
    color: white;
  }

  .small-text {
    font-size: .8em;
  }
}

.map-container-2 {
  height: 100%;
  width: 100%;
  min-width: 90px;
  min-height: 400px;
  z-index: 80;
}




.map-review-wrapper {
  width: 100%;
  height: 25%;
  margin: auto;
  margin-bottom: 20px;
  display: flex;

}

.map-reviews {
  width: 30%;
  height: 100%;
  background-color: #cdcdcd;
  padding: 10px;
  overflow-y: scroll;
  max-height: 400px;

  div {
    background-color: white;
    margin: 10px;
    padding: 10px;
  }

}


.map-wrapper {
  width: 70%;
  height: 25%;
  margin: auto;
  margin-bottom: 20px;
}

@media (max-height: 700px) {
  .map-container-2 {
    min-height: 300px;
  }

  .map-wrapper {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .map-container-2 {
    min-height: 300px;
  }

  .map-wrapper {
    width: 100%;
  }
}


@media (max-height: 600px) {
  .map-container-2 {
    min-height: 200px;
  }

  .map-wrapper {
    width: 100%;
  }
}


@media (max-height: 550px) {
  .map-container-2 {
    min-height: 130px;
  }

  .map-wrapper {
    width: 100%;
  }
}

.reviews-map-title {
  color: #ffb1b1;
  margin: auto;
  width: fit-content;
  text-align: center;
  font-size: 1.3em;
  padding: 1px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  background-color: #393939;
  font-weight: bold;
  font-family: "Inconsolata", monospace;
  opacity: 90%;
  margin-bottom: 10px;
  border-radius: 300px;
  position: relative;

}

.search-title {
  width: fit-content;
  margin: auto;
  margin-bottom: 16px;
  font-size: 1.0em;
  font-style: italic;
  margin-top: 10px;
}

.marker-text {
  background-color: white;

  &:hover {
    cursor: pointer;
  }
}

.join-wrapper {
  max-width: 600px;
  margin: auto;
  margin-top: 20px;
  font-size: 1.3em;
}

.buffer {
  margin-bottom: 400px;
}

.footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;

  button {
    position: relative;
    font-size: .8em;
    margin: auto;
    text-align: center;
    z-index: 50;
  }
}

.view-disclaimer {
  text-align: center;
  width: 100%;

  button {
    border-style: none;
    font-size: 1.1em;
    background-color: #000000;
    color: rgba(255, 255, 255, 0.586);
    letter-spacing: .1em;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: $orange;
  }
}

.report-button {
  position: absolute;
  right: -10px;
  top: 10px;

  button {
    border-style: solid;
    border-color: rgb(255, 128, 0);
    padding: 3px;
    border-width: 1px;
    background-color: transparent;
    border-radius: 30px;

    div {
      background-image: url('flag.png');
      background-size: cover;
      width: 30px;
      height: 30px;
      border-radius: 30px;

    }
  }
}

.report-screen {
  padding-top: 10%;

  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #00000095;
  z-index: 10000;

  h1 {
    font-size: 1.1em;
  }

  .close-btn {
    position: absolute;
    right: 10px;
    background-color: rgb(255, 81, 0);
    color: white;
    font-size: 1.2em;
    padding-bottom: 5px;
  }

  div {
    position: relative;
    background-color: white;
    padding: 10px;
    margin: auto;
    width: fit-content;
    padding-top: 5px;
  }

  textarea {
    color: white;
    padding: 5px;
    font-size: 1.5em;
  }

  button {
    padding: 5px;
    margin: 5px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 1.2em;
    background-color: rgb(59, 216, 11);
    border-style: none;
    border-radius: 30px;
  }

  input {
    border-style: solid;
    border-width: 1px;
  }

  button:disabled {
    color: rgba(255, 255, 255, 0.662);
    background-color: rgb(93, 93, 93);

  }
  
}

.reports-table {
  margin: auto;
  padding: 10px;
  max-width: 600px;

  button {
    font-weight: bold;
    font-size: 1.3em;
  }
}

.owner-address {
  letter-spacing: .05em;
  font-size: 1.1em;
  font-weight: bold;
  color: #e85284;
  padding-top: 10px;
  display: inline-flex;
  flex-grow: 4;
  padding-left: 5px;

  // background-color: #00000079;

}

.owner-marker-inline-wrapper {

  border-color: #e85284;
  border-style: dashed;
  border-width: 2px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 15px;
  margin-top: 5px;
  margin-bottom: 5px;

  .line-1 {
    display: flex;
  }

  .line-2 {
    margin-top: 0px;
    color: white;
  }


}

.owner-marker-inline {
  background-image: url('home_marker.png');
  background-size: cover;
  width: 50px;
  height: 66px;
  cursor: pointer;
  display: inline-flex;
  transform: scale(.7, .7);


}

.main-owner-home-marker {
  @extend .owner-home-marker;
  background-image: url('main_home_marker.png');
  width: 35px;
  height: 80px;
  flex-grow: 4;
  z-index: 100;
}

.owner-title {
  color: #f1e729;
}

.pm-link {
  @extend .via;
  color: black;
  font-size: 1.0em;
  background-color: $lime;
  border-style: none;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.pm-businesses-list {
  @extend .related-businesses-list;
  text-align: center;
  margin: auto;
  margin-top: 50px;
  background-color: #474747;
}

.small {
  font-size: .5em;
}

.contact-list {

  margin: auto;
  width: fit-content;
  max-width: 500px;

  div {
    margin: 8px;
    background-color: whitesmoke;
  }

  .contact-review {
    border-color: #393939;
    border-style: solid;
    padding: 10px;
    padding-left: 20px;
  }

  img {
    max-width: 30px;
    background-color: #fc3200;
    border-radius: 20px;
  }

  button {
    margin: 3px;
    font-size: .9em;
    margin: 5px;
    border-style: solid;
    background-color: #18819e;
    color: white;
    font-weight: bold;

  }

  .added-on {
    text-align: right;
  }

  .copy-to-clip {
    display: inline;
    font-size: .7em;
    border-style: none;
    border-radius: 5px;
  }
}

.pass {
  margin: auto;
  width: fit-content;
}

.select-any-title {
  color: #ffacac;
  padding: 5px;
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 0px;
  font-weight: bold;
}

.selection-questions {
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.selection-question {
  border-radius: 10px;

  flex-grow: 4;
  margin: 4px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
  background-color: #424242;
  color: rgb(187, 187, 187);
  font-size: 1.0em;
  font-weight: bold;
  border-style: none;
  letter-spacing: -.02em;


}

.selection-selected {
  color: black;
  background-color: #ff5ca2;
}

.item-count {
  margin-left: 5px;
  margin-top: 1px;
  padding: 3px;
  background-color: #000000;
  color: white;
  border-radius: 10px;
  font-size: .9em;
  font-weight: bold;
}

.emoji-container {
  position: relative;
}

.issue-emoji-buttons {
  display: flex;
  overflow-x: scroll;
  padding-bottom: 3px;
  background-color: #464646;
  padding-left: 5px;



  @media (min-width: 800px) {

    .fade-out {
      display: none;
    }
  }

  button {
    font-size: 1.2em;
    // background-color: rgb(82, 82, 82);
    background-color: rgba(0, 0, 0, 0.349);
    border-style: solid;

    border-color: rgba(0, 0, 0, 0.768);
    border-radius: 40px;
    padding-bottom: 4px;
    margin: 2px;

    &.selected {
      background-color: rgb(255, 165, 38);
      border-color: $orange;
    }
  }

  .buffer-zone {
    width: 500px;
    padding-right: 200px;
  }

}

.fade-out {
  z-index: 1000000;
  background-color: #000000;
  position: absolute;
  width: 100%;
  height: 50px;
  pointer-events: none;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 75%, $bright 95%);

  &::after {
    font-size: 5.0em;
    position: absolute;
    top: -50%;
    color: rgb(0, 0, 0);
    right: 0px;
    /* Adjust as needed based on border-left width */
    // border-top: 15px solid transparent;
    // border-bottom: 15px solid transparent;
    // border-left: 10px solid rgb(255, 255, 255); /* Adjust color and size as needed */
  }
}

.app-buffer {
  padding-bottom: 30px;
}

.auth-status {
  font-size: 2em;
  text-align: center;
}

.google-login {
  width: fit-content;
  margin: auto;
}

.logout-button {
  width: fit-content;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 10px;

  button {
    border-style: none;
    font-size: 1.8em;
    text-align: center;
  }
}

.super-user {
  margin: auto;
  max-width: 600px;
  background-color: #000000;
  padding: 10px;
  color: white;
  font-size: 1.1em;

  label {
    color: rgb(207, 204, 42);

  }

  input {
    font-size: 1.3em;
    display: inline-flex;
  }

  .email-input {
    min-width: 400px;
  }

  .user {
    padding: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: white;
  }

  button {
    margin: 5px;
    background-color: orange;
    border-style: none;
    border-radius: 20px;
    font-size: 1em;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
  }

  .add {
    background-color: green;
    background-color: rgb(143, 183, 0)
  }

  .delete {
    background-color: rgb(255, 92, 55);
  }
}

.groups {
  width: fit-content;
  margin: auto;

  p {
    background-color: white;
    padding: 5px;
    border-radius: 30px;
  }

  a {
    text-align: right;
    background-color: #007BFF;
    color: white;
  }
}

.multiple-reviews {
  background-color: rgb(181, 200, 71);
  padding: 20px;
  max-width: 600px;
  margin: auto;
  margin-top: 10px;
  border-radius: 30px;
}


.show-users {
  width: fit-content;
  margin: auto;
  text-align: center;

  button {
    font-size: 1.5em;
  }
}

.edit-comment-buttons {
  display: flex;
}

.group-contacts {
  display: block;
  margin: auto;
  width: 80%;
  background: rgb(208, 223, 129);

  div {
    padding: 5px;
  }
}

.tu-title {
  font-size: 2em;
  margin: auto;
  text-align: center;
  width: fit-content;
  padding: 20px;
  color: rgb(227, 244, 128);
}

.tu-desc {
  margin: 10px;

  p {
    font-size: 1.2em;
    margin: auto;
    width: 500px;
    max-width: 80%;
    background-color: rgb(227, 244, 128);
    padding: 10px;
  }
}

.tu-container {
  margin: auto;
  width: fit-content;

  button {
    font-size: 1.5em;
    border-style: none;
    background-color: $green;
    padding: 10px;
    border-radius: 10px;
  }
}

.tenant-popup {
  input {
    max-width: 150px;
  }

  textarea {
    width: 100%;
    -webkit-box-sizing: border-box;
    /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;
    /* Firefox, other Gecko */
    box-sizing: border-box;
    /* Opera/IE 8+ */
    background-color: #80beff;
    border-style: none;
    padding: 10px;
    font-size: 1.5em;

  }

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  max-width: 80%;
  padding: 20px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  /* optional, for styling */
  padding: 20px;
  /* optional, for spacing */

  button {
    border-style: none;
    border-radius: 10px;
    font-size: 1.5em;
    margin: 10px;
    padding: 10px;
  }

  .save-button {
    background-color: rgb(24, 255, 24);
  }
}



.field {
  display: flex;

  label {
    padding-right: 10px;
    ;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input {
    margin: 10px;
    font-size: 1.5em;
  }

  input[type=checkbox] {
    /* Double-sized Checkboxes */
    -ms-transform: scale(2);
    /* IE */
    -moz-transform: scale(2);
    /* FF */
    -webkit-transform: scale(2);
    /* Safari and Chrome */
    -o-transform: scale(2);
    /* Opera */
    transform: scale(2);
    padding: 10px;
  }

  /* Might want to wrap a span around your checkbox text */
  .checkboxtext {
    /* Checkbox text */
    font-size: 110%;
    display: inline;
  }
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Optional: Black see-through */
}

.tenant-list {
  margin: auto;
  margin-top: 20px;
  font-size: 20px;
  padding: 10px;
  width: fit-content;

  tr {
    border-radius: 30px;
    background-color: $bright;
  }

  td {
    padding-left: 10px;
    padding-right: 10px;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  max-width: 100%;
  background-color: white;
}

.tenant-row-notes {
  max-width: 100px;
  overflow: hidden;
}

.close-button {
  position: absolute;
  right: 0%;
  top: 0%;
  color: white;
  border-radius: 400px;
  padding: 5px;
  line-height: .6em;
  background-color: red;
}

.open-button-wrapper {
  position: relative;
}

.open-button {
  background-color: transparent;
  color: rgb(0, 0, 0);
  font-size: 1.5em;
  border-style: solid;
  border-color: black;
  border-radius: 20px;
  width: 40px;
  z-index: 100px;
}

.delete-button {
  background-color: orange;
  font-size: 1.1em;
  border-style: none;
}

.landlord-grid-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.banner-RBOR {
  @extend .banner;
  background: rgb(195, 34, 93);
  background: linear-gradient(147deg, rgb(87, 19, 213) 0%, rgb(30, 120, 230) 100%);
  padding: 10px;
  font-family: 'Montserrat';
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: 70%;
  .vote {
    color: white;
  }
}

.BOR {
  border-style: none !important;
  background-color: rgb(255 191 0) !important;
  color: black !important;
}

.address-container {
  align-items: center;
  text-align: center;
}

.organize-this-building {
  width: 100%;
  button {
    border-style: none;
    border-bottom-style: solid;
    border-bottom-width: 3px;
    border-radius: 100px;
    font-weight: bold;
    font-size: .45em;
    letter-spacing: .2em;
    padding: 5px;
    background-color: #7600fdd0;
    color: white;
    font-style: italic;
    border-color: rgb(0, 0, 0);
    margin: auto;
    text-align: center;
  }
  button::after {
    .organize-this-building:before {
      content: "";
      position: absolute;
      bottom: -20px; /* Adjust position for the tail */
      left: 60%; /* Position the tail relative to the bubble */
      border-width: 10px;
      border-style: solid;
      border-color: #6f1bdd transparent transparent transparent;
    }
  }
}

.tenant-union-join-answer {
  color: black;
  padding: 10px;
  font-weight: bold;
  border-radius: 10px;
  transform-origin: left;
  position: relative;
}

/* Slide in animation */
@keyframes slideIn {
  from {
    height: 0px;
    pointer-events: none;
    opacity: 0;
  }
  to {
    height: 400px;
    pointer-events: auto;
    opacity: 1;
  }
}

/* Slide out animation */
@keyframes slideOut {
  from {
    height: 400px;
    pointer-events: auto;
    opacity: 1;
  }
  to {
    height: 0px;
    pointer-events: none;
    opacity: 0;

  }
}


/* Apply animations */
.grow-in {
  animation: slideIn 0.5s forwards ease-in-out;
}

.grow-out {
  animation: slideOut 0.5s forwards ease-in-out;
}

.suggestion-container {

  .owned_by {
    font-size: .9em;
  }
  margin: auto;
  margin-top: 6px;
  margin-bottom: 10px;
  .or {
    color: white;
  }
  .owned_by {
    color: white;
    span {
      color: $bright;
    }
  }

  background-color: rgb(70, 70, 70);
}

.suggestion {
  margin: auto;
  margin-top: 10px;

  width: 100%;
  padding-left: 10px;
  p {
    font-style: italic;
    margin: auto;
    text-align: center;
    font-size: 1.2em;
  }
  background-color: rgb(160, 168, 13);
  display: inline-flex;
  position: relative;

  .vote-box {
    justify-content: center;
    /* align-self: center;    <---- REMOVE */
    align-items: center; 
    right: 10px;
    margin-right: 10px;
    display: inline-flex;
    .vote-button {
      position: relative;
      div {
        position: absolute;
        top: -7px;
      }
      border-radius: 20px;
      border-style: none;
      font-size: 2.0em;
      width: 20px;
      height: 20px;
      margin: 3px;
      color: rgb(248, 255, 150);
      font-weight: bold;
    }

    .vote-up {
      background-image: url('thumbs-up.png');
      background-size: cover;
    }

    .vote-down {
      background-image: url('thumbs-down.png');
      background-size: cover;
    }

    .green {
      background-color: rgb(58, 128, 0);
      border-style: none;
      box-shadow: none;
    }
    .red {
      background-color: rgb(255, 89, 0);
    }

    .vote-count {
      font-weight: bold;
    }
  }

  border-radius: 20px;

}

.suggested-pms-container {
  margin: auto;
  padding-top: 10px;
  button {
    border-style: solid;
    border-width: 1px;
    border-color: rgb(188, 95, 2);
    color: rgb(244, 129, 13);
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 4px;
    background-color: transparent;
    border-radius: 20px;
    font-size: 1.1em;
  }
}

.determine-suggestions {
  margin: auto;
  width: fit-content;
  padding: 10px;
  p {
    padding: 10px;
  }
  button {
    margin: 10px;
  }
}

.chat-home-button {
  width: 100%;
  margin-top: 50px;
  h3 {
    position: relative;
    text-align: center;
    width: fit-content;
    margin: auto;
    font-size: 1.1em;
    z-index: 20;
    font-style: italic;
    font-weight: normal;
  }
  button {
    position: relative;
    div {
      position: absolute;
      left: -10%;
      top: -10%;
      background: rgb(255, 111, 0);
      width: 40px;
      height: 20px;
      text-align: center;
      font-size: .8em;
      border-radius: 20px;
      font-weight: bold;
      color: rgb(255, 238, 0);
      box-shadow: rgb(255, 221, 0) 0px 0px 10px 4px;
      margin: auto;
    }
    span {
      display: block;
      font-size: 1.5em;
      color: $bright;
    }
    width: 50%;
    font-size: 1.2em;
    padding: 5px;
    display:block; 
    margin: 0 auto;
    border-style: none;
    color: rgb(255, 230, 0);
    background-color: rgb(0, 0, 0);
    animation: 8s outline-effect1 1s infinite;
    border-radius: 20px;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      animation: 8s outline-effect2 0s infinite;
    }
  }


}

@keyframes outline-effect1 {
  0% {
    box-shadow: 0 0 0 0 rgb(255, 179, 0),
                0 0 0 0 rgb(255, 204, 0);
  }
  50% {
    box-shadow: 0 0 0 60px rgba(0, 123, 255, 0),
                0 0 0 90px rgba(255, 123, 0, 0);
  }
  100% {
    box-shadow: 0 0 0 120px rgba(0, 123, 255, 0),
                0 0 0 180px rgba(255, 123, 0, 0);
  }
}

@keyframes outline-effect2 {
  0% {
    box-shadow: 0 0 0 0 rgb(255, 179, 0),
                0 0 0 0 rgba(255, 123, 0, 0.5);
  }
  50% {
    box-shadow: 0 0 0 45px rgba(123, 0, 255, 0),
                0 0 0 75px rgba(0, 255, 123, 0);
  }
  100% {
    box-shadow: 0 0 0 90px rgba(123, 0, 255, 0),
                0 0 0 150px rgba(0, 255, 123, 0);
  }
}

.articles {
  margin-bottom: 4000px;
}

.caret-container {
  margin-top: 30px;
  position: relative;
  width: 100%;
  height: 30px;

}

.caret {
  margin: auto;
  width: 10px;  /* Adjust for desired width */
  height: 10px; /* Adjust for desired height */
  border-bottom: 2px solid black;  /* Left diagonal line */
  border-right: 2px solid black; /* Right diagonal line */

  transform: rotate(45deg); /* Rotate to make the "V" shape *//* This creates the downward-facing triangle */
}

.article {
  padding: 10px;
  margin: auto;
  margin-bottom: 10px;
  border-radius: 20px;
  display: flex;
  color: white;
  font-size: 1.08em;
  justify-content: center;
  align-items: center;
  border-style: none;
  .article-image {
    max-width: 30%;
    padding-right: 10px;
  }

  &:hover {
    background-color: #465b09;

  }

  background-color: rgb(0, 0, 0);
  max-width: 600px;
}