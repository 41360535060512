*:focus {
    outline: none;
}

.absCenter {
    //display: inline;
    margin: 10px;
    position: relative;
    z-index: 100;
}



.infoButton {
    appearance: none;
    border: 0;
    background-color: transparent;
    padding: 0;
    margin: 0;
    font-size: 14px;
    position: absolute;
    bottom: -10px;
}

.infoButton-btn {
    appearance: none;
    background-color: transparent;
    border: 2px solid rgb(0, 0, 0);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    color: rgb(0, 0, 0);
    background-color: rgb(233, 233, 233);
    font-weight: 700;
    transform: rotate(-45deg);
    transition: border-radius 200ms ease-in-out;
    
}

.infoButton-btn-text {
    display: block;
    text-align: center;
    width: 20px;
    height: 20px;
    line-height: 20px;
    transform: rotate(45deg);
    
}

.infoButton-container {
    position: absolute;
    bottom: calc(-100% + 60px);
    right: 0;
    margin-right: -90px;
    transform: scale(0);
    transition: transform 0ms linear 200ms;
}

.infoButton-container-message {
    box-sizing: border-box;
    width: 200px;
    padding: 16px 20px;
    font-size: 14px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.26);
    border-radius: 4px;
    line-height: 1.3;
    text-align: left;
    transform: translateY(-50px);
    opacity: 0;
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
    z-index: 10;
    background-color: rgb(255, 255, 255);
    position: absolute;
    left: -240px;
    bottom: -150px;
}

.infoButton:focus .infoButton-btn,
.infoButton_isActive .infoButton-btn {
    border-bottom-left-radius: 0;
    transition: border-radius 200ms ease-in-out;
    background-color: rgb(0, 0, 0);
    color: white;
}

.infoButton:focus .infoButton-container,
.infoButton_isActive .infoButton-container {
    transform: scale(1);
    transition: transform 0ms linear;
}

.infoButton:focus
.infoButton-container-message,
.infoButton_isActive
.infoButton-container-message {
    transform: translate(0);
    opacity: 1;
}
